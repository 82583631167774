import {defineStore} from "pinia"
import type {MediaListType} from '~/types/MediaListType'
import {useNodeStore} from "~/stores/node"
import {useSearchStore} from "~/stores/search"
import type {MediaCardData} from "~/types/media/MediaCardData"
import type {ComputedRef} from 'vue'

export const useMediaListStore = defineStore('media-list', () => {

    const pageSize = 32
    const listType = ref<MediaListType>(null)
    const nodeStore = useNodeStore()
    const searchStore = useSearchStore()
    const router = useRouter()

    const currentPage = computed(() => {
        return router.currentRoute.value.query.p ? Number(router.currentRoute.value.query.p) : 1
    })

    function listNextPage() {
        return navigateTo({
            ...router.currentRoute.value,
            query: {
                ...router.currentRoute.value.query,
                p: currentPage.value + 1
            }}, {replace: true})
    }

    const mediaList = computed<MediaCardData[]>(() => {
        if (listType.value === 'node') {
            return Object.values(nodeStore.nodeMedias)
        }

        if (listType.value === 'search') {
            return searchStore.searchMedias
        }

        return []
    })

    const listedMedias: ComputedRef<MediaCardData[]> = computed(() => {
        return mediaList.value.slice(0, (currentPage.value) * pageSize)
    })

    const hasMoreMedias = computed(() => {
        return Object.values(mediaList.value).length > listedMedias.value.length
    })

    function getNode(id:string|number, andMakeCurrent: boolean = true) {
        listType.value = 'node'

        return nodeStore.getNode(Number(id), andMakeCurrent)
    }

    const currentNode = computed(() => {
        return nodeStore.currentNode
    })

    function search(q: string, andMakeCurrent = true) {
        listType.value = 'search'

        return searchStore.search(q, andMakeCurrent)
    }

    const currentSearchQuery = computed(() => {
        return searchStore.currentSearchQuery
    })

    return {
        listType,

        listedMedias,
        hasMoreMedias,
        listNextPage,

        getNode,
        currentNode,

        search,
        currentSearchQuery,
    }
})